<template>
  <vs-card class="p-2 BaseQuestionManage">
    <base-question-modal 
      ref="modal"
      mode="manage"
      @action="questionAction($event)"
      @new-question-pressed="newQuestion"
    />
    <vs-popup
      :active.sync="showForm"
      :title="popupTitle"
      fullscreen="true"
    >
      <base-question-form
        ref="form"
        @confirm-alteration="storeQuestion"
        @cancel-alteration="showForm = false"
        :preserveModel="true"
        :service="service"
      />
    </vs-popup>
  </vs-card>
</template>

<script>
import BaseQuestionService from '@/services/api/BaseQuestionService'
import BaseQuestionForm from './questionnaires/elements/basequestion/BaseQuestionForm.vue'
import BaseQuestionModal from './questionnaires/elements/basequestion/BaseQuestionModal.vue'
// import { BaseQuestion, Question } from '@/models/cnt/Questionnaire'


export default {
  components: { BaseQuestionModal, BaseQuestionForm },

  data: () => ({
    showForm: false,
    service: null,
    popupTitle: ''
  }),

  beforeMount() {
    this.service = BaseQuestionService.build(this.$vs)
  },

  watch: {
    showForm(open) {
      if (!open) {
        this.resetForm()
      }
    }
  },

  methods: {
    questionAction(action) {
      if (action && (action.type === 'edit' || action.type === 'customize')) {
        this.fillPopupTitle(action.data)
        this.$refs['form'].fill(action.data)
        this.$refs['form'].redirty(JSON.parse(action.data.data))
        this.showForm = true
      } else if (action && (action.type === 'destroy')) {
        this.fillPopupTitle(action.data)
        const dialogtext = this.$t('base_question.destroy_sure', [this.popupTitle])
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: this.$t('confirmacao'),
          acceptText: this.$t('sim'),
          cancelText: this.$t('nao'),
          text: dialogtext,
          accept: () => this.destroyQuestion(action.data.id)
        })
      }
    },

    fillPopupTitle(question) {
      if (question) {
        const exists = `${this.$t('questao')} ${question.id} ${question.origin ? `- ${question.origin}` : ''}`
        const custom = question.parent_id ? this.$t('personalizar-questao-question-parent_id', [question.parent_id]) : this.$t('personalizar-questao')
        this.popupTitle = question.id !== null ? exists : custom
      }
    },
    resetGrid() {
      this.$refs['modal'].$refs['baseQuestionGrid'].$refs['grid'].fetchData(true)
    },
    resetForm() {
      this.$refs['form'].resetForm()
    },

    newQuestion(type) {
      this.popupTitle = this.$t('nova-questao')
      
      const question = {
        icon: this.$utils.format.vsButtonMaterialIcon.fromQuestionType(type),
        id: this.$uuidKey(),
        data: {
          required: false,
          title: '',
          wrong_feedback: null,
          correct_feedback: null,
          options: []
        },
        type: type,
        name: '',
        handle: false,
      }
      const newQuestionScaffold = {
        id: null,
        data: JSON.stringify(question),
        title: '',
        uuid: this.$uuidKey(),
        owner_id: this.$store.state.AppActiveUser.id
      }

      this.$refs['form'].fill(newQuestionScaffold)
      this.showForm = true
    },

    storeQuestion(alteration) {
      if (this.service && alteration && alteration.data) {
        this.$vs.loading()
        this.service.createOrUpdate(alteration.data).then(
          (newlyStored) => {
            this.$vs.loading.close()
            this.resetGrid()
            this.showForm = false
            const newlabel = alteration.type === 'create' ? ' nova' : ''
            this.notifySuccess(
              this.$vs,
              this.$t('base_question.store_success_indexed', [newlabel, alteration.data.id]),
              10000
            )
          },
          (error) => {
            this.$vs.loading.close()
            this.notifyError(this.$vs, this.$t('base_question.store_error'))
            const errorLabel = 'statusText' in error ?
              `${error.status} ${error.statusText}` : undefined
            console.error(errorLabel || error)
          }
        )
      }
    },

    destroyQuestion(id) {
      if (this.service && id) {
        this.$vs.loading()
        this.service.destroy(id).then(
          (success) => {
            this.$vs.loading.close()
            this.resetGrid()
            this.notifySuccess(this.$vs, this.$t('base_question.destroy_success'), 10000)
          },
          (error) => {
            this.$vs.loading.close()
            this.notifyError(this.$vs, this.$t('base_question.destroy_error'))
            const errorLabel = 'statusText' in error ?
              `${error.status} ${error.statusText}` : undefined
            console.error(errorLabel || error)
          }
        )
      }
    }

  }
}
</script>

<style lang="scss">
.BaseQuestionManage .BaseQuestionCard {
    .vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
      padding: 0.5rem;
    }
}
</style>