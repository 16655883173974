<template>

  <div class="grid grid-cols-12 gap-2 BaseQuestionForm">
    
    <vs-card class="col-span-12 QuestionCard" @item-ITEM_UPDATED="redirty">
      <component :dontCloseEditor="true" :autoOpenEditor="true" class="pt-4" mode="form" :is="componentType" :item="question"/>
    </vs-card>

    <vs-divider class="my-1 col-span-12"/>

    <fieldset class="col-span-12 gap-2 flex flex-wrap flex-row grid-cols-2 p-4">
      <legend style="padding: 0 5px 0 5px;">{{$t('base_question.form.question_data')}}</legend>

      <vs-select
        class="w-full sm:w-1/2 flex-shrink"
        :label="$t('fields.education_level')"
        :disabled="true"
        v-model="staticview.education_level_id"
        autocomplete
      >
        <vs-select-item :value="0" :text="$t('term.none')" />
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in selectOptions.education_level" />
      </vs-select>

      <vs-select
        class="flex-grow flex-shrink"
        :disabled="true"
        :label="$t('fields.course')"
        v-model="staticview.course_id"
      >
        <vs-select-item :value="0" :text="$t('term.none')" />
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in selectOptions.course" />
      </vs-select>

      <vs-select
        class="w-full sm:w-1/2 flex-shrink"
        :label="$t('fields.discipline')"
        v-model="mutableModel.discipline_id"
        autocomplete
        :noData="$t('base_question.form.no_discipline_data')"
      >
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in selectOptions.discipline" />
      </vs-select>

      <vs-select
        class="flex-grow flex-shrink"
        :label="$t('fields.difficulty')"
        v-model="mutableModel.difficulty_level"
      >
        <vs-select-item :key="index" :value="item.value" :text="$t(item.text)" v-for="(item,index) in selectOptions.difficulty" />
      </vs-select>

      <TagSuggestion
        class="w-full sm:w-1/2 flex-shrink"
        label="Temas"
        v-model="mutableModel.themes"
        model="StudyTheme"
        :placeholderText="$t('base_question.form.type_themes')"
        :modelToTagMapping="mapModelToTag"
      />

      <TagSuggestion
        class="flex-grow flex-shrink"
        label="Sub-Temas"
        v-model="mutableModel.sub_themes"
        model="StudySubTheme"
        :placeholderText="$t('base_question.form.type_subthemes')"
        :modelToTagMapping="mapModelToTag"
      />

    </fieldset>

    <fieldset class="col-span-12 row-span-2 p-4">
      <legend style="padding: 0 5px 0 5px;">{{$t('fields.origin')}}</legend>
      <div class="grid grid-cols-4 gap-2 items-start">
        <vs-input
          class="w-full col-span-2"
          :label="$t(`fields.reference`)"
          v-model="mutableModel.origin.reference"
          :name="`origin_name`"
        />
        <div class="w-full col-span-2 flex self-end">
          <SelectSuggestion
            class="flex-grow"
            :label="$t(`fields.institution`)"
            ref="InstitutionSelect"
            v-model="mutableModel.origin.institution"
            :itemTextFormat="(model) => { return model.name }"
            :name="`origin_institution_id`"
            :appendClearOption="true"
            :placeholderText="$t('base_question.form.type_institution')"
            model="BaseQuestionInstitution"
          />
        </div>
        <div class="w-full">
          <vs-input
            v-validate="'numeric|between:1,9999'"
            class="w-full"
            :label="$t(`fields.year`)"
            v-model="mutableModel.origin.year"
            v-mask="['####']"
            :name="`Ano`"
          />
          <span class="text-danger text-sm">{{ errors.first('Ano') }}</span>
        </div>
        <vs-input
          class="w-full"
          :label="$t(`fields.term`)"
          v-model="mutableModel.origin.term"
          :name="`origin_term`"
        />
        <span class="text-danger text-sm" v-show="errors.has(`origin`)">{{
          errors.first(`origin`)
        }}</span>
      </div>
    </fieldset>

    <fieldset class="col-span-12 gap-2 flex flex-wrap flex-row grid-cols-2 p-4">
      <legend style="padding: 0 5px 0 5px;">{{$t('base_question.form.feedback')}}</legend>

      <vs-tabs :color="colorx" v-model="tabIndex">
          <vs-tab :label="$t('resposta-correta')">
            <ComplexEditor
              v-bind:editor_data.sync="mutableModel.correct_feedback"
              :placeholder="$t('digite-aqui-o-feedback')"
            ></ComplexEditor>
          </vs-tab>
          <vs-tab :label="$t('resposta-incorreta')">
            <ComplexEditor
              v-bind:editor_data.sync="mutableModel.wrong_feedback"
              :placeholder="$t('digite-aqui-o-feedback')"
            ></ComplexEditor>
          </vs-tab>
        </vs-tabs>
    </fieldset>

    <div class="col-span-12 flex gap-4 items-center justify-end">
      <vs-button :disabled="invalidForm" @click="store">{{ $t('action.save') }}</vs-button>
      <vs-button type="border" @click="cancel">{{ $t('action.cancel') }}</vs-button>
    </div>

  </div>
  
</template>

<script>
import Vue from 'vue'
import CheckBox from '../questions/CheckBox.vue'
import MultipleChoice from '../questions/MultipleChoice.vue'
import SimpleText from '../questions/SimpleText.vue'
import { BaseQuestion, Question } from '@/models/cnt/Questionnaire'
import { isString, upperFirst, camelCase } from 'lodash'
import BaseQuestionService from '@/services/api/BaseQuestionService'
import TagSuggestion from '@/components/TagSuggestion.vue'
import DisciplineService from '@/services/api/DisciplineService'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
const ComplexEditor = () => import('../ComplexEditor')

export default Vue.extend({
  components: {
    CheckBox,
    MultipleChoice,
    SimpleText,
    TagSuggestion,
    SelectSuggestion,
    ComplexEditor
  },

  props: {
    preserveModel: {
      default: false,
      type: Boolean
    },
    service: {
      default: null,
      type: BaseQuestionService,
    }
  },

  data: () => ({
    model: null,
    dirtyQuestion: null,
    mutableModel: {
      discipline_id: null,
      difficulty_level: 'E',
      origin: {
        reference: '',
        year: (new Date()).getFullYear(),
        term: '',
        institution: {},
      },
      correct_feedback: '',
      wrong_feedback: '', 
      themes: [],
      sub_themes: [],
    },
    staticview: {
      course_id: 0,
      education_level_id: 0
    },
    selectOptionsFetched: false,
    selectOptions: {
      discipline: [],
      difficulty: [
        {text: 'base_question.difficulty.E', value: 'E'},
        {text: 'base_question.difficulty.M', value: 'M'},
        {text: 'base_question.difficulty.D', value: 'D'}
      ],
      course: [],
      education_level: []
    },
    tabIndex: 0
  }),

  watch: {
    discipline(discipline) {
      this.fillStaticOption(discipline, 'course')
      this.fillStaticOption(discipline, 'education_level')
    }
  },

  computed: {

    question() {
      if (this.model && isString(this.model.data)) {
        return JSON.parse(this.model.data)
      }
      return null
    },

    discipline() {
      const discipline_id = this.mutableModel.discipline_id
      if (this.isEmpty(discipline_id)) {
        return undefined
      }
      return this.selectOptions.discipline.find((disciplineOption) => {
        return disciplineOption.value === this.mutableModel.discipline_id
      })
    },

    dirtyModel() {
      // Only return the updated BaseQuestion if the question has been updated
      if (this.model) {

        let question = {...this.dirtyQuestion}
        if (question && this.model.id === null) { question.id = this.$uuidKey() }

        if (this.mutableModel.correct_feedback) {
          question.data.correct_feedback = this.mutableModel.correct_feedback
        }

        if (this.mutableModel.wrong_feedback) {
          question.data.wrong_feedback = this.mutableModel.wrong_feedback
        }

        question = JSON.stringify(question)
        const origin = {...this.mutableModel.origin}
        const institution = {...origin.institution}
        delete origin.institution
        origin.base_question_institution_id = (institution && institution.id) || null

        const model = {
          ...this.model,
          ...this.mutableModel,
          data: question,
          title: this.dirtyQuestion && this.dirtyQuestion.data.title,
          question_origin: origin,
          origin: `${institution.name || ''} ${origin.reference || ''} ${origin.year || ''} ${origin.term || ''}`,
          themes: this.mutableModel.themes.map((a) => a.data),
          sub_themes: this.mutableModel.sub_themes.map((a) => a.data)
        }

        return model
      }
      return this.model
    },

    componentType() {
      const type = this.questionType
      if (type) {
        return upperFirst(camelCase(type))
      }
      return 'div'
    },
    questionType() {
      return (this.question && this.question.type) || undefined
    },
    selectedInstitution() {
      return !!(this.mutableModel &&
        this.mutableModel.origin &&
        this.mutableModel.origin.institution &&
        !this.isEmpty(this.mutableModel.origin.institution.id))
    },

    invalidForm() {
      /*  We won't be using this for now.
      if (this.model) {
        const hasNoQuestionData = this.isEmpty(this.model.data)
        const didNotChangeAnything = this.isEmpty(this.dirtyQuestion)
        const titleIsEmpty = this.dirtyQuestion && !didNotChangeAnything && this.isEmpty(this.dirtyQuestion.data.title)
        return hasNoQuestionData || didNotChangeAnything || titleIsEmpty
      }
      */
      return !(this.dirtyModel && this.dirtyModel.data && this.dirtyModel.title) && !this.validationErrors
    },

    validationErrors() {
      return this.errors.items.length
    },

    validService() {
      return this.service instanceof BaseQuestionService
    },
  },

  methods: {
    fillSelectOptions(which, options) {
      // if (!options.find((option) => {
      //   return option.value === 'none'
      // })) {
      // }
      this.selectOptions[which] = [... options]
      this.selectOptions[which].sort((first, second) => {
        return first.text.toLowerCase() > second.text.toLowerCase()
      })
      this.selectOptions[which].unshift({
        text: this.$t('term.none'),
        value: null
      })
    },
    setDisciplineOptions() {
      const disciplineService = DisciplineService.build(this.$vs)
      disciplineService.getDisciplineSelectOptions().then(
        (data) => {
          this.fillSelectOptions('discipline', data)
        },
        (error) => {
          console.error(error)
        }
      )
    },
    emitAlterationEvent(action) {
      if (this.model) {
        const operation = this.model.id ? 'update' : 'create'
        this.$emit(`${action}-alteration`, {type: operation, data: {...this.dirtyModel}, id: this.model.id})
        if (!this.preserveModel) {
          this.resetForm()
        }
      }
    },
    fill(data) {
      this.model = data
      this.fillMutables(data)
    },
    fillMutables(model) {
      const formatAsTag = (thing) => ({text: thing.title, })
      this.mutableModel = {
        ...this.mutableModel,
        ...model,
        themes: (model.themes && model.themes.map(this.mapModelToTag)) || [],
        sub_themes: (model.sub_themes && model.sub_themes.map(this.mapModelToTag)) || []
      }

      if (this.mutableModel.data) {
        const questionData = JSON.parse(this.mutableModel.data)
        this.mutableModel.correct_feedback = questionData.data.correct_feedback
        this.mutableModel.wrong_feedback = questionData.data.wrong_feedback
      }


      if ('question_origin' in model) {
        if (model.question_origin) {
          this.mutableModel.origin = model.question_origin
          if ('institution' in model.question_origin && model.question_origin.institution) {
            this.$refs['InstitutionSelect'].fill(model.question_origin.institution)
          }
        } else {
          this.mutableModel.origin = {
            reference: '',
            year: (new Date()).getFullYear(),
            term: '',
            institution: {},
            correct_feedback: '',
            wrong_feedback: ''
          }
        }
        
      }
    },
    redirty(question) {
      this.dirtyQuestion = question
    },
    store() {
      if (this.model) {
        this.emitAlterationEvent('confirm')
      }
    },
    cancel() {
      this.emitAlterationEvent('cancel')
    },
    resetForm() {
      this.dirtyQuestion = null
      this.model = null
      this.mutableModel = {
        discipline_id: null,
        difficulty_level: 'E',
        origin: {
          reference: '',
          year: (new Date()).getFullYear(),
          term: '',
          institution: {},
        }, 
        themes: [],
        sub_themes: [],
        correct_feedback: '',
        wrong_feedback: '',
      }
    },
    clearInstitution() {
      if (this.selectedInstitution) {
        this.$refs['InstitutionSelect'].reset()
      }
    },
    /*
    Automatic filling of a static option when discipline 
    is selected.
    */
    fillStaticOption(discipline, which) {
      if (discipline && which in discipline && discipline[which]) {
        const model = discipline[which]
        this.selectOptions[which] = [{text: model.name, value: model.id}]
        this.staticview[`${which}_id`] = model.id
      } else {
        this.selectOptions[which] = []
        this.staticview[`${which}_id`] = 0
      }
    },
    /*
    Map themes and subthemes into tags, It is sent to TagSuggestion component,
    and also applied when opening a question to edit and correctly format
    theme and subthemes in tags.
    */
    mapModelToTag(model) {
      return {
        text: model.name,
        data: model
      }
    }
  },

  mounted() {
    this.setDisciplineOptions()
  }
  
})
</script>

<style lang="scss">
.BaseQuestionForm > .QuestionCard {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
</style>