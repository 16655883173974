import Rest from '../Rest'

export default class DisciplineService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/disciplines'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  getDisciplineSelectOptions() {
    return this.get(`/selectoptions/extended`).then( data => {
      return data.map((discipline) => {
        const course = discipline.course || null
        const educationLevel = (course && course.education_levels) || null
        if (educationLevel) delete course.education_levels
        return {
          text: discipline.title,
          value: discipline.id,
          course: course,
          education_level: educationLevel
        }
      })
    })
  }
}
