var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid grid-cols-12 gap-2 BaseQuestionForm" },
    [
      _c(
        "vs-card",
        {
          staticClass: "col-span-12 QuestionCard",
          on: { "item-ITEM_UPDATED": _vm.redirty },
        },
        [
          _c(_vm.componentType, {
            tag: "component",
            staticClass: "pt-4",
            attrs: {
              dontCloseEditor: true,
              autoOpenEditor: true,
              mode: "form",
              item: _vm.question,
            },
          }),
        ],
        1
      ),
      _c("vs-divider", { staticClass: "my-1 col-span-12" }),
      _c(
        "fieldset",
        {
          staticClass:
            "col-span-12 gap-2 flex flex-wrap flex-row grid-cols-2 p-4",
        },
        [
          _c("legend", { staticStyle: { padding: "0 5px 0 5px" } }, [
            _vm._v(_vm._s(_vm.$t("base_question.form.question_data"))),
          ]),
          _c(
            "vs-select",
            {
              staticClass: "w-full sm:w-1/2 flex-shrink",
              attrs: {
                label: _vm.$t("fields.education_level"),
                disabled: true,
                autocomplete: "",
              },
              model: {
                value: _vm.staticview.education_level_id,
                callback: function ($$v) {
                  _vm.$set(_vm.staticview, "education_level_id", $$v)
                },
                expression: "staticview.education_level_id",
              },
            },
            [
              _c("vs-select-item", {
                attrs: { value: 0, text: _vm.$t("term.none") },
              }),
              _vm._l(_vm.selectOptions.education_level, function (item, index) {
                return _c("vs-select-item", {
                  key: index,
                  attrs: { value: item.value, text: item.text },
                })
              }),
            ],
            2
          ),
          _c(
            "vs-select",
            {
              staticClass: "flex-grow flex-shrink",
              attrs: { disabled: true, label: _vm.$t("fields.course") },
              model: {
                value: _vm.staticview.course_id,
                callback: function ($$v) {
                  _vm.$set(_vm.staticview, "course_id", $$v)
                },
                expression: "staticview.course_id",
              },
            },
            [
              _c("vs-select-item", {
                attrs: { value: 0, text: _vm.$t("term.none") },
              }),
              _vm._l(_vm.selectOptions.course, function (item, index) {
                return _c("vs-select-item", {
                  key: index,
                  attrs: { value: item.value, text: item.text },
                })
              }),
            ],
            2
          ),
          _c(
            "vs-select",
            {
              staticClass: "w-full sm:w-1/2 flex-shrink",
              attrs: {
                label: _vm.$t("fields.discipline"),
                autocomplete: "",
                noData: _vm.$t("base_question.form.no_discipline_data"),
              },
              model: {
                value: _vm.mutableModel.discipline_id,
                callback: function ($$v) {
                  _vm.$set(_vm.mutableModel, "discipline_id", $$v)
                },
                expression: "mutableModel.discipline_id",
              },
            },
            _vm._l(_vm.selectOptions.discipline, function (item, index) {
              return _c("vs-select-item", {
                key: index,
                attrs: { value: item.value, text: item.text },
              })
            }),
            1
          ),
          _c(
            "vs-select",
            {
              staticClass: "flex-grow flex-shrink",
              attrs: { label: _vm.$t("fields.difficulty") },
              model: {
                value: _vm.mutableModel.difficulty_level,
                callback: function ($$v) {
                  _vm.$set(_vm.mutableModel, "difficulty_level", $$v)
                },
                expression: "mutableModel.difficulty_level",
              },
            },
            _vm._l(_vm.selectOptions.difficulty, function (item, index) {
              return _c("vs-select-item", {
                key: index,
                attrs: { value: item.value, text: _vm.$t(item.text) },
              })
            }),
            1
          ),
          _c("TagSuggestion", {
            staticClass: "w-full sm:w-1/2 flex-shrink",
            attrs: {
              label: "Temas",
              model: "StudyTheme",
              placeholderText: _vm.$t("base_question.form.type_themes"),
              modelToTagMapping: _vm.mapModelToTag,
            },
            model: {
              value: _vm.mutableModel.themes,
              callback: function ($$v) {
                _vm.$set(_vm.mutableModel, "themes", $$v)
              },
              expression: "mutableModel.themes",
            },
          }),
          _c("TagSuggestion", {
            staticClass: "flex-grow flex-shrink",
            attrs: {
              label: "Sub-Temas",
              model: "StudySubTheme",
              placeholderText: _vm.$t("base_question.form.type_subthemes"),
              modelToTagMapping: _vm.mapModelToTag,
            },
            model: {
              value: _vm.mutableModel.sub_themes,
              callback: function ($$v) {
                _vm.$set(_vm.mutableModel, "sub_themes", $$v)
              },
              expression: "mutableModel.sub_themes",
            },
          }),
        ],
        1
      ),
      _c("fieldset", { staticClass: "col-span-12 row-span-2 p-4" }, [
        _c("legend", { staticStyle: { padding: "0 5px 0 5px" } }, [
          _vm._v(_vm._s(_vm.$t("fields.origin"))),
        ]),
        _c(
          "div",
          { staticClass: "grid grid-cols-4 gap-2 items-start" },
          [
            _c("vs-input", {
              staticClass: "w-full col-span-2",
              attrs: { label: _vm.$t("fields.reference"), name: "origin_name" },
              model: {
                value: _vm.mutableModel.origin.reference,
                callback: function ($$v) {
                  _vm.$set(_vm.mutableModel.origin, "reference", $$v)
                },
                expression: "mutableModel.origin.reference",
              },
            }),
            _c(
              "div",
              { staticClass: "w-full col-span-2 flex self-end" },
              [
                _c("SelectSuggestion", {
                  ref: "InstitutionSelect",
                  staticClass: "flex-grow",
                  attrs: {
                    label: _vm.$t("fields.institution"),
                    itemTextFormat: function (model) {
                      return model.name
                    },
                    name: "origin_institution_id",
                    appendClearOption: true,
                    placeholderText: _vm.$t(
                      "base_question.form.type_institution"
                    ),
                    model: "BaseQuestionInstitution",
                  },
                  model: {
                    value: _vm.mutableModel.origin.institution,
                    callback: function ($$v) {
                      _vm.$set(_vm.mutableModel.origin, "institution", $$v)
                    },
                    expression: "mutableModel.origin.institution",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "numeric|between:1,9999",
                      expression: "'numeric|between:1,9999'",
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: ["####"],
                      expression: "['####']",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: { label: _vm.$t("fields.year"), name: "Ano" },
                  model: {
                    value: _vm.mutableModel.origin.year,
                    callback: function ($$v) {
                      _vm.$set(_vm.mutableModel.origin, "year", $$v)
                    },
                    expression: "mutableModel.origin.year",
                  },
                }),
                _c("span", { staticClass: "text-danger text-sm" }, [
                  _vm._v(_vm._s(_vm.errors.first("Ano"))),
                ]),
              ],
              1
            ),
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { label: _vm.$t("fields.term"), name: "origin_term" },
              model: {
                value: _vm.mutableModel.origin.term,
                callback: function ($$v) {
                  _vm.$set(_vm.mutableModel.origin, "term", $$v)
                },
                expression: "mutableModel.origin.term",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("origin"),
                    expression: "errors.has(`origin`)",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("origin")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "fieldset",
        {
          staticClass:
            "col-span-12 gap-2 flex flex-wrap flex-row grid-cols-2 p-4",
        },
        [
          _c("legend", { staticStyle: { padding: "0 5px 0 5px" } }, [
            _vm._v(_vm._s(_vm.$t("base_question.form.feedback"))),
          ]),
          _c(
            "vs-tabs",
            {
              attrs: { color: _vm.colorx },
              model: {
                value: _vm.tabIndex,
                callback: function ($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex",
              },
            },
            [
              _c(
                "vs-tab",
                { attrs: { label: _vm.$t("resposta-correta") } },
                [
                  _c("ComplexEditor", {
                    attrs: {
                      editor_data: _vm.mutableModel.correct_feedback,
                      placeholder: _vm.$t("digite-aqui-o-feedback"),
                    },
                    on: {
                      "update:editor_data": function ($event) {
                        return _vm.$set(
                          _vm.mutableModel,
                          "correct_feedback",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-tab",
                { attrs: { label: _vm.$t("resposta-incorreta") } },
                [
                  _c("ComplexEditor", {
                    attrs: {
                      editor_data: _vm.mutableModel.wrong_feedback,
                      placeholder: _vm.$t("digite-aqui-o-feedback"),
                    },
                    on: {
                      "update:editor_data": function ($event) {
                        return _vm.$set(
                          _vm.mutableModel,
                          "wrong_feedback",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-span-12 flex gap-4 items-center justify-end" },
        [
          _c(
            "vs-button",
            { attrs: { disabled: _vm.invalidForm }, on: { click: _vm.store } },
            [_vm._v(_vm._s(_vm.$t("action.save")))]
          ),
          _c(
            "vs-button",
            { attrs: { type: "border" }, on: { click: _vm.cancel } },
            [_vm._v(_vm._s(_vm.$t("action.cancel")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }