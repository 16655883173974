
<template>
  <div class="panel-margin relative pb-2 _question-panel">
    <div class="__difficulty-text">
      <difficulty-indicator :difficulty="_.get(item, 'data.difficulty', '')"/>
    </div>
    <div class="flex mb-4">
      <div class="bg-grid-color-secondary mr-2" style="width: 100%">
        <!-- <TitleComponent
          placeholder="Pergunta"
          v-show="!showComplexEditor"
          :item="item"
          ref='title'
        ></TitleComponent> -->

        <ComplexEditor
          :questionnaire_id="parent_id"
          v-bind:editor_data.sync="editorData"
          v-on:blur="onBlur"
          v-on:destroy="onDestroy"
          @data-changed="onDataChange"
          :placeholder="`${$t('digite-a-questao-aqui')}...`"
          :autoOpen="autoOpenEditor"
          :dontClose="dontCloseEditor"
        ></ComplexEditor>
      </div>
      <!-- <div class="bg-grid-color-secondary" style="width: 5%">
        <vs-button
          type="filled"
          icon="edit"
          class="footer-button"
          @click="switchEditor()"
          v-tooltip.bottom-start="{
            content: 'Alternar editor',
            delay: { show: 1000, hide: 100 },
          }"
        ></vs-button>
      </div> -->
    </div>
    <Footer :showInvalidateButton="showInvalidateButton" v-if="questionnaireMode" :item="item" :parent_id="parent_id" :cheetEnabled="cheetEnabled"></Footer>
  </div>
</template>

<script>
import TitleComponent from './title/TitleComponent'
import ComplexEditor from '../ComplexEditor'
import Footer from '../Footer'
import DifficultyIndicator from '@/components/DifficultyIndicator.vue'

export default {
  components: {
    TitleComponent,
    ComplexEditor,
    Footer,
    DifficultyIndicator,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    parent_id: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      default: 'questionnaire'
    },
    autoOpenEditor: {
      type: Boolean,
      default: false
    },
    dontCloseEditor: {
      type: Boolean,
      default: false
    },
    showInvalidateButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    cheetEnabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showComplexEditor: false,
      editorData: this.item !== null ? this.item.data.title : '',
    }
  },
  computed: {
    questionnaireMode() {
      return this.mode === 'questionnaire'
    },
    formMode() {
      return this.mode === 'form'
    }
  },
  methods: {
    switchEditor() {
      this.showComplexEditor = !this.showComplexEditor
      this.item.data.editor_type = this.showComplexEditor ? 'complex' : 'simple'
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    onBlur() {
      this.updateItem()
    },
    updateItem() {
      this.item.data.title = this.editorData
      this.item.data.editor_type = 'complex'
      this.$parent.$emit('item-ITEM_UPDATED', this.item)
    },
    onDataChange() {
      this.updateItem()
    },
    onEditorDestroy() {
      this.item.data.title = this.html2text(this.editorData)
      this.item.data.editor_type = 'complex'
      //this.$refs.title.$emit('HTML_TITLE_UPDATED', this.item)
    },
  },
  mounted() {

    this.$emit('questionRendered')

    if (this.item.data.editor_type === 'complex') {
      this.showComplexEditor = true
    }

    this.$on('item-TITLE_UPDATED', (item, type, value) => {
      if (this.item.id === item.id) {
        const data = this._.cloneDeep(event.data)
        data.title = value
        this.editorData = value
        this.$parent.$emit('item-ITEM_UPDATED', this.item)
      }
    })

    this.$on('footer-DELETE_ITEM', (item) => {
      this.$parent.$emit('item-DELETE_ITEM', item)
    })

    this.$on('footer-INVALIDATE_ITEM', (item) => {
      this.$parent.$emit('item-INVALIDATE_ITEM', item)
    })

    this.$on('footer-REVALIDATE_ITEM', (data) => {
      this.$parent.$emit('item-REVALIDATE_ITEM', data)
    })

    this.$on('footer-DUPLICATE_ITEM', (item) => {
      this.$parent.$emit('item-DUPLICATE_ITEM', item)
    })

    this.$on('footer-ITEM_UPDATED', (item) => {
      this.$parent.$emit('item-ITEM_UPDATED', item)
    })
  }
}
</script>

<style>
</style>
